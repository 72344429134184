import React, {useState,useEffect} from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import {Image} from "react-bootstrap"
import Link from "gatsby-link";
import img from "../images/blog.jpg"
import { Helmet } from 'react-helmet';
import GET_ORDER_LIST from "../queries/get-order-list"
import { useQuery } from "@apollo/client";
import {graphql} from "gatsby"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {isUserLoggedIn} from "../utils/functions"
import { navigate } from "gatsby"
import moment from "moment"

//import {OrderTypes} from "../queries/get-order-list"
//import GET_ORDER_LIST_COMPLETED from "../queries/get-order-list-completed"
const OrderList = () => {
  useEffect(() => {
    if(!isUserLoggedIn()) {
      const returnURl = window.location.href
      return navigate("/signin?returnurl="+returnURl)
    }
  }, []);
  const {t} = useTranslation();
  const [orderList, setOrderList] = useState([]);
  const [hasNextPage, setNextPage] = useState(false);
  const [pageSize, setPageSize] = useState(1);
  //const [orderType, setOrderType] = useState(null);
  const { data, refetch } = useQuery(GET_ORDER_LIST, {
    fetchPolicy:"cache-and-network",
    variables: {
     // where:{statuses:OrderTypes.FAILED},
      first:5,
    },
    returnPartialData:true,
    onCompleted: () => {
      setOrderList(data.customer.orders.edges)
      setNextPage(data.customer.orders.pageInfo.hasNextPage)
      setPageSize(pageSize+1)
    },
    onError: (error) => {
      console.log(error)
    },
  });
 
  
  const showMore = () =>{
    refetch({
      first: pageSize * 5
    })
  }


  return(
    <div>
      <HeardTopWrapper title={t('orderlist')}/>
      <Layout>
        <Helmet>
           <title>{t('myKitchenInspector')}｜{t('orderlist')}</title>
        </Helmet>
        <PageContainer>
          {/* status filter */}
        {/* <Nav defaultActiveKey="All" className="order-status-filter" onSelect={(selectedKey) => orderTypeFilter(`${selectedKey}`)}>
          <Nav.Item>
            <Nav.Link eventKey="All">All</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="COMPLETED">COMPLETED</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="FAILED">FAILED</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="ON_HOLD">ON HOLD</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="PENDING">PENDING</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="PROCESSING">PROCESSING</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="REFUNDED">REFUNDED</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="FAILED">FAILED</Nav.Link>
          </Nav.Item>
        </Nav> */}
          {
            orderList?
            <div className="tb-wrap">
             <div className="tbl tb-header">
               <div className="cell">
                {t('orderID')}
               </div>
               <div className="cell">
               {t('date')}
               </div>
               <div className="cell product-cell">
               {t('product')}
               </div>
               <div className="cell">
               {t('price')}
               </div>
               <div className="cell">
               {t('status')}
               </div>
               <div className="cell">
               {t('action')}
               </div>
             </div>
             {
               orderList.map((item,index) =>{
                return  <div className="tbl" key={`item_${index}`}>
                 <div className="cell cell-orderID">
                  <span className="tb-label">{t('orderID')}</span> <Link to={`/order/orderDetail?${item.node.id}`}>{item.node.orderNumber}</Link>
                 </div>
                 <div className="cell">
                   <span className="tb-label">{t('date')}</span> {item.node.datePaid ? moment(item.node.datePaid).format('YYYY-MM-DD') : ""}
                 </div>
                 <div className="cell product-cell">
                   <span className="tb-label">{t('product')}</span> 
                   <div>
                    {item.node.lineItems.edges.map((productList,index) =>{
                      return <div className="product-info" key={`productList_${index}`}>
                      <div className="product-info-img">
                        <Image src={productList.node.product.image ? productList.node.product.image.link : img}/>
                      </div>
                      <h5>{productList.node.product.name}  × {productList.node.quantity}</h5>
                    </div>
                    })}
                   </div>
                 </div>
                 <div className="cell">
                   <span className="tb-label">{t('price')}</span> 
                   {item.node.total}
                   
                 </div>
  
                 <div className="cell">
                   <span className="tb-label">{t('status')}</span> 
                   <span className={item.node.status === "REFUNDED" ? "dot dot-refund" 
                   : item.node.status === "CANCELLED" ?  "dot dot-cancel"
                   : item.node.status === "COMPLETED" ?  "dot dot-complete"
                   : item.node.status === "FAILED" ?  "dot dot-refund"
                   : item.node.status === "ON_HOLD" ?  "dot dot-paying"
                   : item.node.status === "PENDING" ?  "dot dot-paying"
                   : item.node.status === "PROCESSING" ?  "dot dot-paying"
                   :""
                  }></span> {item.node.status === "REFUNDED" ? t('REFUNDED')
                              : item.node.status === "CANCELLED" ?  t('CANCELLED')
                              : item.node.status === "COMPLETED" ?  t('COMPLETED')
                              : item.node.status === "FAILED" ?  t('FAILED')
                              : item.node.status === "ON_HOLD" ?  t('ON_HOLD')
                              : item.node.status === "PENDING" ?  t('PENDING')
                              : item.node.status === "PROCESSING" ?  t('PROCESSING')
                              :""
                            }
                 </div>
                 <div className="cell">
                 {item.node.status === "REFUNDED" ? "" 
                   : item.node.status === "CANCELLED" ?  ""
                   : item.node.status === "COMPLETED" ?  ""
                   : item.node.status === "FAILED" ?  ""
                  //  <div><Button className="addButton payButton">{t('Pay')}</Button><Button className="addButton">{t('Cancel')}</Button></div>
                   : item.node.status === "ON_HOLD" ?  ""
                   : item.node.status === "PENDING" ?   ""
                   : item.node.status === "PROCESSING" ?  ""
                   :""
                  }
                 </div>
                
               </div>
               
               })
               
             }
             {
               hasNextPage?
               <div className="text-center show-more"><a onClick={showMore}>{t('showMore')}</a></div>
               :""
             }
             
             </div>
             :<h2 className="text-center">{t('noItemsInOrder')}</h2>
          }
           

          
        </PageContainer>
      </Layout>

  </div>

  )
}

export default OrderList

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;