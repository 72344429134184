import { gql } from "@apollo/client";
const GET_ORDER_LIST = gql`
    query GET_ORDER_LIST($first: Int){
      customer {
        orders(first: $first) {
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            node{
              id
              orderNumber
              total
              status
              datePaid
              orderKey
              lineItems {
                edges {
                  node {
                    product {
                      name
                      image {
                        link
                      }
                    }
                    quantity
                  }
                }
              }
            }
           
          }
        }

      }
      
    }
`;

export default GET_ORDER_LIST;
